<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center">
            <div class="d-flex justify-content-between w-100 align-items-center">
                <div class="d-flex align-items-center mb-1">
                    <h4 class="mb-0">{{ $t('menu.subcategory.wolt_menu') }}</h4>
                </div>

                <div class="d-flex align-items-center mb-1">
                    <h4 v-if="totalUnmappedCount > 0">
                        {{ $t('wolt.total_unmapped') }} {{ totalUnmappedCount }}
                    </h4>
                </div>
            </div>
            <div v-if="getActiveItems.status !== 'PENDING'" class="x-axis-custom-scroll" style="max-width: 100%; max-height: 100px; overflow-x: auto; overflow-y: hidden; padding-bottom: 5px;">
                <b-tabs-header :tabs="getTitles" @clickTab="handleClickTab" :activeTab.sync="activeTab" :show-close="false"/>
            </div>

            <div v-for="item in getActiveItems.items" :key="item.id" class="customCard" style="margin-top: 25px;">
                <div class="row" style="cursor: pointer" @click="openDetailsModal(item)">

                    <div class="col-md-9">
                        <div  class="d-flex align-items-center" style="font-weight: bold; font-size: 18px; padding-bottom:8px;">
                            <div v-if="item.item.product.external_id === '' || item.options.some(option => option.values.some(value => value.product.external_id === ''))" style="color: #f85d5d; font-size: 25px; font-weight: bold;">
                                !
                            </div>
                            <div :class="{'ml-1': item.item.product.external_id === '' || item.options.some(option => option.values.some(value => value.product.external_id === ''))}" style="text-transform: uppercase; padding-right: 10px;">
                                {{ getTextInLocale(item.item, 'product.name') }}
                            </div>

                            <span v-if="item.item.product.external_id === '' && item.options.some(option => option.values.some(value => value.product.external_id === ''))" style="font-size: 12px; color: #f85d5d;">
                                ({{ $t('wolt.addon_and_item_not_mapped') }})
                            </span>

                            <span v-else-if="item.item.product.external_id === ''" style="font-size: 12px; color: #f85d5d;">
                                ({{ $t('wolt.item_not_mapped') }})
                            </span>

                            <span v-else-if="item.item.product.external_id !== '' && item.options.some(option => option.values.some(value => value.product.external_id === ''))" style="font-size: 12px; color: #f85d5d;">
                                ({{ $t('wolt.addon_not_mapped') }})
                            </span>
                        </div>
                        <div style="white-space: pre-line;">
                            {{ getTextInLocale(item.item, 'product.description') }}
                        </div>
                        <div style="padding-top: 15px;font-weight: bold;">
                            {{ item.item.price/100 }}€
                        </div>
                    </div>
                    <div class="d-flex col-md-3 justify-content-end">
                        <img :src="item.item.product.image_url" alt="Image of food." width="200" style="border-radius: 18px; object-fit: cover; max-height: 130px;">
                    </div>
                </div>
            </div>
        </b-card>
        <wolt-item-details ref="woltItemDetails"></wolt-item-details>
    </b-overlay>
</template>

<script>
    import {BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'
    import WoltItemDetails from '@/views/Wolt/WoltItemDetails.vue'

    export default {
        components: {WoltItemDetails, BTabsHeader, BCard, BOverlay},
        computed: {
            getTitles() {
                if (this.menu && this.menu.categories) {

                    return this.menu.categories.map(category => {
                        const nameObject = category.name.find(name => name.lang === this.getLanguage)
                        const categoryTitle = nameObject ? nameObject.value : category.name[0]

                        const categoryData = this.menu.products_per_category.find(cat => cat.category_id === category.id)

                        let unmappedCount = 0

                        if (categoryData && categoryData.items) {
                            unmappedCount = categoryData.items.reduce((count, item) => {
                                const isProductUnmapped = item.item.product.external_id === ''
                                const hasUnmappedOptions = item.options.some(option => option.values.some(value => value.product.external_id === '')
                                )
                                return count + (isProductUnmapped || hasUnmappedOptions ? 1 : 0)
                            }, 0)
                        }

                        return {
                            title: categoryTitle,
                            unmapped: unmappedCount,
                            id: category.id
                        }
                    })
                }
                return []
            },
            getActiveItems() {
                if (this.menu && this.menu.products_per_category) {
                    const items = this.menu.products_per_category.find((category) => category.category_id === this.getTitles[this.activeTab].id)

                    if (items && items.items && items.items.length > 0) {
                        return items
                    }
                }
                return []
            },
            totalUnmappedCount() {
                let total = 0

                if (this.menu && this.menu.categories) {
                    this.menu.categories.forEach(category => {
                        const categoryData = this.menu.products_per_category.find(cat => cat.category_id === category.id)
                        if (categoryData && categoryData.items) {
                            categoryData.items.forEach(item => {
                                const isProductUnmapped = item.item.product.external_id === ''
                                const hasUnmappedOptions = item.options.some(option => option.values.some(value => value.product.external_id === '')
                                )
                                if (isProductUnmapped || hasUnmappedOptions) {
                                    total += 1
                                }
                            })
                        }
                    })
                }

                return total
            },

            getLanguage() {
                return this.$store.getters['user/language']
            }
        },
        data() {
            return {
                menu: {},
                showLoader: false,
                activeTab: 0
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/wolt/menu')
                    this.menu = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            getTextInLocale(item, selector) {
                const keys = selector.split('.')
                const value = keys.reduce((obj, key) => obj && obj[key], item)

                if (value) {
                    const text = value.find(value => value.lang === this.getLanguage)

                    if (text) {
                        return text.value.trim()
                    }
                }
            },
            openDetailsModal(item) {
                this.$refs.woltItemDetails.open(item)
            },
            handleClickTab(index) {
                this.activeTab = index
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style lang="scss">

    .x-axis-custom-scroll {
      &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
      }
    }

    .dark-layout {
      .x-axis-custom-scroll {
        &::-webkit-scrollbar-track {
          background: #303030;
        }

        &::-webkit-scrollbar-thumb {
          background: #505050;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #595959;
        }
      }
    }

    .light-layout {
      .x-axis-custom-scroll {
        &::-webkit-scrollbar-track {
          background: rgba(200, 200, 200, 0.7);
        }

        &::-webkit-scrollbar-thumb {
          background: #d3d3d3;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #c0c0c0;
        }
      }
    }

    .dark-layout {
      .customCard {
        background: #343434 !important;
        color: #d0d2d6;
      }
    }

    .light-layout {
      .customCard {
        background: rgba(247, 247, 247, 0.97) !important;
        color: #6e6b7b;
      }
    }

    .customCard {
      border-radius: 18px;
      padding: 25px 20px;
    }
</style>
