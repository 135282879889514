<template>
    <b-modal ref="woltItemDetailsModal" :title="getTitle" size="md" hide-footer>
        <div v-if="item && item.item && item.item.product">
            <img :src="item.item.product.image_url" alt="Food image." width="100%" height="auto" style="border-radius: 18px;"/>

            <div class="mt-2 p-2 customCard" style="white-space: pre-line;">
                <div class="d-flex align-items-center">
                    <div v-if="item.item.product.external_id === '' " style="color: #f85d5d; font-size: 25px; font-weight: bold;">
                        !
                    </div>
                    <div :class="{'ml-1': item.item.product.external_id === ''}" style="text-transform: uppercase; padding-right: 10px;">
                        {{ getTextInLocale(item.item, 'product.name') }}
                    </div>
                    <span v-if="item.item.product.external_id === '' " style="font-size: 12px; color: #f85d5d;">
                        (izdelek ni mapiran)
                    </span>
                </div>
                {{ getTextInLocale(item.item, 'product.description') }}

                <div class="pt-1" style="font-weight: bold;">
                    {{item.item.price/100}}€
                </div>
            </div>

            <div>
                <div v-for="item in item.options" :key="item.id">
                    <div class="pt-1 pb-1" style="font-weight: bold;">
                        {{ getTextInLocale(item, 'name') }}
                    </div>
                    <ul>
                        <li v-for="value in item.values" :key="value.id">
                            <div class="d-flex align-items-center">
                                <div v-if="value.product.external_id === ''" style="color: #f85d5d; font-size: 16px; font-weight: bold;">
                                    !
                                </div>
                                <div :class="{'paddingForNotMapOrders': value.product.external_id === ''}" >
                                    {{ getTextInLocale(value.product, 'name') }}
                                </div>
                                <span v-if="value.product.external_id  === '' " style="font-size: 12px; color: #f85d5d;">
                                    (Dodatek ni mapiran)
                                </span>
                            </div>
                            <span v-if="value.price > 0" class="font-small-2">(+{{value.price/100}}€)</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </b-modal>
</template>


<script>
    import { BModal } from 'bootstrap-vue'

    export default {
        components:{
            BModal
        },
        data() {
            return {
                item: {}
            }
        },
        props:{
        },
        computed: {
            getTitle() {
                if (this.item && this.item.item && this.item.item.product) {
                    const name = this.item.item.product.name.find(name => name.lang === this.getLanguage)

                    if (name) {
                        return name.value
                    }
                }
                return ''
            },
            getLanguage() {
                return this.$store.getters['user/language']
            }
        },
        methods:{
            open(data) {
                this.item = data
                this.$refs.woltItemDetailsModal.show()
            },
            hide() {
                this.resetVariables()
                this.$refs.woltItemDetailsModal.hide()
            },
            resetVariables() {
                this.item = {}
            },
            getTextInLocale(item, selector) {
                const keys = selector.split('.')
                const value = keys.reduce((obj, key) => obj && obj[key], item)

                if (value) {
                    const text = value.find(value => value.lang === this.getLanguage)

                    if (text) {
                        return text.value.trim()
                    }
                }
            }
        }
    }
</script>

<style>
 .paddingForNotMapOrders{
   padding: 0px 5px;
 }
</style>